.b-navigation-bar {
  --text-color: var(--font-light-color);
  --dark-text-color: var(--font-dark-color);
  --search-background-color: var(--black-to-white-color);
  --button-image-color: var(--primary-color);
  z-index: 450;
}

.b-navigation-bar,
.MuiAppBar-root.b-navigation-bar {
  justify-content: space-between;
  margin-bottom: 20px;
  background: var(--primary-gradient);
  z-index: 1260;
}

.b-navigation-bar .b-navigation-bar__toolbar {
  min-height: 44px;
  transition: 0.4s;
  padding: 0;
}

.b-navigation-bar__left-side {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.b-back-button {
  margin-right: var(--small-margin);
}

.b-back-button svg {
  margin-left: -3px;
}

.b-back-button--toggle-search,
.b-back-button {
  background: transparent;
  border: none;
  color: var(--text-color);
  padding: 0;
}

.with-search-autocomplete .b-back-button--toggle-search {
  display: inherit;
}

.with-search-autocomplete .b-navigation-bar__toolbar {
  background-color: var(--white-color);
  transition: 0.4s;
}

.with-search-autocomplete .b-navigation-bar__search-box {
  background: var(--search-background-color);
}

.with-search-autocomplete .b-back-button svg {
  stroke: var(--button-image-color);
  width: var(--big-size);
}

.b-back-button--toggle-search,
.b-back-button > svg {
  width: var(--big-size);
}

.b-navigation-bar__title {
  margin-bottom: 0;
  color: var(--text-color);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 60px);
  padding: 4px 0;
  font-size: 1rem;
  font-weight: normal;
  margin-right: auto;
}

.b-navigation-bar__offline {
  color: var(--text-color);
  padding-left: 20px;
  font-size: 0.8rem;
  line-height: 1.6rem;
}

.b-navigation-bar__search-box + .b-navigation-bar__offline {
  margin-top: 4px;
}

body.with-navbar {
  padding-top: 44px;
}

.b-navigation-bar__search-box {
  background: var(--white-color);
  color: var(--dark-text-color);
  border-radius: var(--border-radius);
  left: 46px;
  right: 0;
  top: 6px;
  display: flex;
  padding-right: var(--tiny-padding);
  padding-left: var(--common-padding);
  flex-basis: 150%;
  min-height: 26px;
}

.b-navigation-bar__search-box .b-selection-search__search-button {
  padding: 0;
  width: 40px;
}

.b-navigation-bar__search-box .b-selection-search {
  padding: 0;
  border-radius: 0;
  border: none;
}

.b-navigation-bar__toolbar .b-toggle-search-button {
  color: var(--text-color);
  margin-right: var(--medium-margin);
}

.b-back-button--toggle-search,
.b-back-button--search-opened {
  color: var(--dark-text-color);
}

body .b-navigation-bar__popover {
  margin-top: 49px;
  transform: none!important;
  left: unset!important;
  right: 5px;
}
