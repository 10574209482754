.b-autocomplete {
  width: 100%;
  position: relative;
}

.b-autocomplete__content {
  margin-top: 4px;
  position: absolute;
  width: 100%;
  background: var(--white-color);
  left: 0;
  padding-top: var(--medium-padding);
}

.b-autocomplete--dropdown .b-autocomplete__content {
  border-radius: var(--border-radius);
  border: 1px solid var(--black-to-white-color);
  z-index: 1300;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

.b-autocomplete__list {
  padding: 0;
  margin: 0;
}

.b-autocomplete__item {
  list-style-type: none;
  padding: 4px;
  padding-left: var(--average-padding);
  padding-right: var(--big-padding);
  min-height: 43px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.b-autocomplete__item-thumbnail {
  height: 35px;
}

.b-autocomplete__item-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0;
  font-size: var(--small-size);
  margin-right: var(--big-margin);

}

.b-autocomplete__item a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.b-autocomplete__item + .b-autocomplete__item {
  border-top: 1px solid var(--black-to-white-color);
}

@media (max-width: 767.98px) {
  .b-autocomplete {
    position: static;
  }

  .b-autocomplete__content {
    height: 100vh;
    left: 0;
    right: 0;
  }

  .with-search-autocomplete {
    overflow: hidden;
  }

  .b-autocomplete--dropdown .b-autocomplete__content {
    box-shadow: none;
    border-radius: 0;
  }
}
