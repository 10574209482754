.b-selection-search {
  --search-border-color: rgba(var(--primary-color-rgb), 0.2);
}

.b-selection-search {
  flex-grow: 1;
}

.b-selection-search__content {
  position: relative;
  padding: 0 10px;
  display: flex;
  border: 1px solid var(--search-border-color);
  border-radius: var(--border-radius);
}

.b-selection-search .b-selection-search__root {
  flex-grow: 1;
}

.b-selection-search .b-selection-search__input {
  flex-grow: 1;
  padding-top: 9px;
}

