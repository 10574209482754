.b-main-menu {
  --background-color: var(--white-color);
  --border-color: var(--black-to-white-color);
}

.b-main-menu {
  overflow: hidden;
  z-index: 1260;
  padding: 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--background-color);
  border-top: 1px solid var(--border-color);
}

.b-main-menu__button-group {
  width: 100%;
  justify-content: space-between;
  display: inline-flex;
  vertical-align: middle;
  text-align: center;
  padding: var(--tiny-padding) 0;
}

.b-main-menu__button-group .b-main-menu__button-label {
  font-size: var(--tiny-size);
  margin: 0;
}

.b-main-menu__button-group .b-main-menu__button-signature--padding-left {
  padding-left: 4px;
}

body.with-main-menu {
  padding-bottom: 46px;
}

.b-main-menu__button {
  color: var(--font-dark-color);
  position: relative;
  text-align: center;
  background: transparent;
  border: none;
  padding: 0;
  flex-grow: 1;
}

.b-main-menu__button.active {
  color: var(--font-dark-color-highlighted);
}

.b-main-menu__button:hover {
  color: var(--font-dark-color-highlighted);
}

.b-main-menu__label {
  position: absolute;
  right: 0;
  top: 0;
}
