.b-cart-button {
  position: relative;
  color: hsl(
    var(--primary-color-hsl-hue),
    var(--hsl-saturate),
    var(--hsl-light)
  );
}

.b-cart-button__icon-wrapper {
  position: relative;
  display: inline-block;
}
