.b-history-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 100%;
  text-align: left;
  color: var(--link-color);
  padding: 0px;
  font-size: var(--small-size);
}

.b-history-button:hover {
  color: var(--link-color-highlighted);
}

.b-history-button__icon {
  margin-right: var(--common-margin);
}
