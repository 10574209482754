.b-gdpr-agreement {
  padding: var(--average-padding);
  background-color: var(--primary-color);
  color: var(--white-color);
  font-weight: bold;
}

.b-gdpr-agreement__header {
  margin-bottom: var(--average-margin);
  text-transform: uppercase;
}

.b-gdpr-agreement__footer {
  text-align: center;
}

.b-gdpr-agreement__accept-button {
  width: 100%;
}

.b-gdpr-agreement__accept-button:hover {
  opacity: 0.9;
}

@media (min-width: 768px) {
  .b-gdpr-agreement__accept-button {
    width: unset;
  }
}
