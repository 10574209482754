.ph-item {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  margin-bottom: 10px;
  background-color: #fff;
  /* border: 1px solid #e6e6e6; */
  /* border-radius: 2px */
}

.ph-item::before {
  content: ' ';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  z-index: 1;
  width: 500%;
  margin-left: -250%;
  animation: phAnimation .8s linear infinite;
  background: linear-gradient(90deg, hsla(0, 0%, 100%, 0) 46%, hsla(0, 0%, 100%, .35) 50%, hsla(0, 0%, 100%, 0) 54%) 50% 50%
}

.ph-avatar {
  position: relative;
  width: 100%;
  min-width: 60px;
  background-color: #ced4da;
  margin-bottom: 15px;
  border-radius: 50%;
  overflow: hidden
}

.ph-avatar::before {
  content: ' ';
  display: block;
  padding-top: 100%
}

.ph-picture {
  width: 100%;
  background-color: #ced4da;
  margin-bottom: 15px;
}

.ph-text {
  width: 100%;
  background-color: #ced4da;
  height: 1rem;
  margin-bottom: var(--common-margin);
}

.ph-text.big {
  height: 2rem;
}

.ph-picture.square::after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

@keyframes phAnimation {
  0% {
    transform: translate3d(-30%, 0, 0)
  }
  to {
    transform: translate3d(30%, 0, 0)
  }
}

