.b-page-load-indicator {
  position: fixed;
  top: 0;
  z-index: 1800;
  width: 100%;
  height: 2px;
  background: linear-gradient(124deg, #ff2400, #e81d1d, #e8b71d, #e3e81d, #1de840, #1ddde8, #2b1de8, #dd00f3, #dd00f3);
  background-size: 800% 120%;
  animation: rainbow 4s ease infinite;
}

@keyframes rainbow {
  0% { background-position: 0% 50% }
  50% { background-position: 200% 50% }
  100% { background-position: 0% 50% }
}
