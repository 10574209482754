.b-label {
  display: block;
  position: absolute;
  right: -0.5rem;
  top: -0.3rem;
  width: var(--normal-size);
  height: var(--normal-size);
  font-size: var(--tiny-size);
  z-index: 10;
  background: var(--secondary-gradient);
  border-radius: 50%;
  text-align: center;
  color: var(--white-color);
  font-family: var(--font-family-important);
}
