:root {
  --transparency-forn-background-light: rgba(247, 247, 247, 0.7);

  /* guidline color settings */
  /* hue */
  --primary-color-hsl-hue: 198;
  --secondary-color-hsl-hue: calc(var(--primary-color-hsl-hue) + 120);
  --tertiary-color-hsl-hue: calc(var(--primary-color-hsl-hue) + 240);

  /* saturate */
  --hsl-saturate: 100%;

  /* light */
  --hsl-light: 47%;
  --hsl-more-light: calc(var(--hsl-light) + 10%);

  /* opacity */
  --opacity-maximum: 1;
  --opacity-less: 0.8;

  /* colors*/
  --primary-color: hsla(
    var(--primary-color-hsl-hue),
    var(--hsl-saturate),
    var(--hsl-light),
    var(--opacity-maximum)
  );
  --secondary-color: hsla(
    var(--secondary-color-hsl-hue),
    var(--hsl-saturate),
    var(--hsl-light),
    var(--opacity-maximum)
  );
  --tertiary-color: hsla(
    var(--tertiary-color-hsl-hue),
    var(--hsl-saturate),
    var(--hsl-light),
    var(--opacity-maximum)
  );

  --outline-primary-color: hsla(
    var(--primary-color-hsl-hue),
    var(--hsl-saturate),
    var(--hsl-light),
    0.5
  );
  /* base black and white colors */
  --black-color:            hsla(0, 0%, 15%, 100%);
  --black-to-gray-color:    hsla(0, 0%, 15%, 60%);
  --black-to-silver-color:  hsla(0, 0%, 15%, 20%);
  --black-to-white-color:   hsla(0, 0%, 15%, 5%);

  --white-color:            hsla(0, 0%, 100%, 100%);
  --white-to-silver-color:  hsla(0, 0%, 100%, 60%);
  --white-to-gray-color:    hsla(0, 0%, 100%, 20%);
  --white-to-black-color:   hsla(0, 0%, 100%, 5%);

  /* gradients */
  --primary-gradient: linear-gradient(
    90deg,
    hsla(
      var(--primary-color-hsl-hue),
      var(--hsl-saturate),
      var(--hsl-light),
      1
    ),
    hsla(
      calc(var(--primary-color-hsl-hue) - 20),
      var(--hsl-saturate),
      var(--hsl-more-light),
      1
    )
  );

  --secondary-gradient: linear-gradient(
    38deg,
    hsla(
      var(--secondary-color-hsl-hue),
      var(--hsl-saturate),
      var(--hsl-light),
      1
    ),
    hsla(
      calc(var(--secondary-color-hsl-hue)),
      var(--hsl-saturate),
      var(--hsl-more-light),
      1
    )
  );

  --tertiary-gradient: linear-gradient(
    38deg,
    hsla(
      var(--tertiary-color-hsl-hue),
      var(--hsl-saturate),
      var(--hsl-light),
      1
    ),
    hsla(
      calc(var(--tertiary-color-hsl-hue)),
      var(--hsl-saturate),
      var(--hsl-more-light),
      1
    )
  );

  /* sizes */
  --huge-size:    1.875rem; /* 30px */
  --big-size:     1.5rem;   /* 24px */
  --normal-size:  1rem;     /* 16px */
  --small-size:   0.875rem; /* 14px */
  --little-size:  0.75rem;  /* 12px */
  --tiny-size:    0.625rem; /* 10px */
  --micro-size:   0.5rem;   /* 8px */

  /* indents */
  --giant-margin: 48px;
  --huge-margin: 32px;
  --large-margin: 24px;
  --big-margin: 20px;
  --average-margin: 16px;
  --medium-margin: 12px;
  --common-margin: 10px;
  --small-margin: 8px;
  --tiny-margin: 5px;

  --giant-padding: 48px;
  --huge-padding: 32px;
  --large-padding: 24px;
  --big-padding: 20px;
  --average-padding: 16px;
  --medium-padding: 12px;
  --common-padding: 10px;
  --small-padding: 8px;
  --tiny-padding: 5px;

  /* text */
  --font-light-color:             var(--white-color);
  --font-light-color-highlighted: var(--white-to-silver-color);
  --font-dark-color:              var(--black-color);
  --font-dark-color-highlighted:  var(--black-to-gray-color);
  --font-color:                   var(--font-dark-color);

  --link-light-color:             var(--primary-color);
  --link-light-color-highlighted: var(--primary-color);
  --link-dark-color:              var(--black-color);
  --link-dark-color-highlighted:  var(--primary-color);
  --link-color:                   var(--link-dark-color);
  --link-color-highlighted:       var(--link-dark-color-highlighted);

  /* font-sizes */
  --font-tiny-size:               var(--tiny-size);
  --font-little-size:             var(--little-size);
  --font-small-size:              var(--small-size);
  /* fonts */
  --font-family-regular: Roboto, sans-serif;
  --font-family-important: Ubuntu, sans-serif;

  /* - */

  --primary-color-rgb: 88, 152, 182;
  --secondary-color-rgb: 0, 199, 256;
  --tertiary-color-rgb: 240, 251, 255;

  --quaternary-color: #E6F4F1;
  --quaternary-color-hsl-hue: 167;
  --quaternary-color-rgb: 230, 244, 241;

  --transition-time: 0.4s;
  --danger-color: #e00d34;

  --danger-color-hsl-hue: 349;
  --warning-color-hsl-hue: 54;

  --border-radius: 4px;

  --common-margin: 10px;
  --common-padding: 10px;

  --big-margin: 20px;
  --big-padding: 20px;

  --hsl-light-font: 97%;
  --hsl-dark-font: 5%;

  /* Gradients */
  /* Default */
  --button-color: var(--primary-gradient);
  --button-color-active: var(--primary-color);
  --button-color-disabled: var(--primary-color);
  /* Secondary */
  --button-secondary-color: var(--secondary-gradient);
  --button-secondary-color-active: var(--secondary-color);
  --button-secondary-color-disabled: var(--secondary-color);
  /* Tetriary */
  --button-tertiary-color: var(--tertiary-gradient);
  --button-tertiary-color-active: var(--tertiary-color);
  --button-tertiary-color-disabled: var(--tertiary-color);

  /* Black & White */
  --button-dark-gradient:
    linear-gradient(
      38deg,
      hsl(
        var(--danger-color-hsl-hue),
        0%,
        var(--hsl-light)
      ),
      hsl(
        calc(var(--danger-color-hsl-hue) + 20),
        0%,
        var(--hsl-more-light)
      )
    );

  --button-dark-gradient-active:
    linear-gradient(
      38deg,
      hsl(
        var(--danger-color-hsl-hue),
        0%,
        var(--hsl-dark-light)
      ),
      hsl(
        calc(var(--danger-color-hsl-hue) + 20),
        0%,
        var(--hsl-less-light)
      )
    );
}

.main-theme {
  --primary-color-hsl-hue: 200;
  --hsl-saturate: 98%;
  --hsl-light: 47%;
}

.blue-theme {
  --primary-color-hsl-hue: 200;
  --hsl-saturate: 98%;
  --hsl-light: 47%;
}

.dark-blue-theme {
  --primary-color-hsl-hue: 216;
  --hsl-saturate: 100%;
  --hsl-light: 52%;
}

.blue-to-purple-theme {
  --primary-color-hsl-hue: 216;
  --hsl-saturate: 100%;
  --hsl-light: 64%;
}

.purple-theme {
  --primary-color-hsl-hue: 240;
  --hsl-saturate: 100%;
  --hsl-light: 50%;
}

.dark-purple-theme {
  --primary-color-hsl-hue: 245;
  --hsl-saturate: 69%;
  --hsl-light: 55%;
}

.black-theme {
  --primary-color-hsl-hue: 220;
  --hsl-saturate: 100%;
  --hsl-light: 0%;
}

.red-theme {
  --primary-color-hsl-hue: 340;
  --hsl-saturate: 100%;
  --hsl-light: 50%;
}

.double-color-theme.blue-and-orange-theme {
  --primary-color-hsl-hue: 210;
  --primary-color-hsl-saturate: 33%;
  --primary-color-hsl-light: 9%;

  --secondary-color-hsl-hue: 34;
  --secondary-color-hsl-saturate: 86%;
  --secondary-color-hsl-light: 62%;
}

.double-color-theme.orange-and-dark-blue-theme {
  --primary-color-hsl-hue: 34;
  --primary-color-hsl-saturate: 86%;
  --primary-color-hsl-light: 62%;

  --secondary-color-hsl-hue: 210;
  --secondary-color-hsl-saturate: 33%;
  --secondary-color-hsl-light: 25%;
}

.double-color-theme.copper-coin-and-aged-copper-theme {
  --primary-color-hsl-hue: 187;
  --primary-color-hsl-saturate: 61%;
  --primary-color-hsl-light: 60%;

  --secondary-color-hsl-hue: 12;
  --secondary-color-hsl-saturate: 39%;
  --secondary-color-hsl-light: 50%;
}

.double-color-theme {
  --primary-color: hsla(
    var(--primary-color-hsl-hue),
    var(--primary-color-hsl-saturate),
    var(--primary-color-hsl-light),
    var(--opacity-maximum)
  );
  --secondary-color: hsla(
    var(--secondary-color-hsl-hue),
    var(--secondary-color-hsl-saturate),
    var(--secondary-color-hsl-light),
    var(--opacity-maximum)
  );

  --primary-gradient: linear-gradient(
    90deg,
    hsla(
      var(--primary-color-hsl-hue),
      var(--primary-color-hsl-saturate),
      var(--primary-color-hsl-light),
      1
    ),
    hsla(
      calc(var(--primary-color-hsl-hue) - 20),
      var(--primary-color-hsl-saturate),
      var(--primary-color-hsl-light),
      1
    )
  );

  --secondary-gradient: linear-gradient(
    38deg,
    hsla(
      var(--secondary-color-hsl-hue),
      var(--secondary-color-hsl-saturate),
      var(--secondary-color-hsl-light),
      1
    ),
    hsla(
      calc(var(--secondary-color-hsl-hue)),
      var(--secondary-color-hsl-saturate),
      var(--secondary-color-hsl-light),
      1
    )
  );
}
