html, body, #root {
  min-height: 100%;
  margin: 0;
  overscroll-behavior-x: none;
  font-family: var(--font-family-regular);
}

h1, h2, h3 {
  font-weight: bold;
  font-family: var(--font-family-important);
}

h1, h2 {
  font-size: var(--big-size);
}

h3 {
  font-size: var(--normal-size);
}

.container.no-padding {
  padding-left: 0;
  padding-right: 0;
}

.b-combined-svg > svg:first-child {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.b-combined-svg.b-combined-svg--hidden > svg:first-child {
  display: none;
}

.b-combined-svg.b-combined-svg--bottom-right > svg:first-child {
  bottom: 5px;
  right: 5px;
  left: auto;
}

.huge-title {
  font-size: 10em;
  font-weight: bold;
  font-family: Helvetica;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1), 0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3), 0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15);
}

.rotate {
  animation-name: rotate;
  transform-origin: center center;
}

a {
  color: var(--link-color);
}

a:hover,
a:focus {
  color: var(--link-color-highlighted);
  text-decoration: none;
}

.b-button {
  display: inline-block;
  font-weight: 400;
  color: var(--font-dark-color);
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background: transparent;
  padding: var(--common-padding) var(--big-padding);
  font-size: 1rem;
  border: none;
  border-radius: var(--border-radius);
  transition: color .15s ease-in-out,opacity .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.b-button--fluid {
  width: 100%;
}

.b-button:active,
.b-button:hover,
.b-button:focus {
  text-decoration: none;
}

/* primary button */
.b-button--primary {
  color: var(--font-light-color);
  background: var(--button-color);
}

.b-button--primary:disabled,
.b-button--primary:disabled:active,
.b-button--primary:disabled:hover,
.b-button--primary:disabled:focus {
  filter: grayscale(1) brightness(1.2);
  background: var(--button-color-disabled);
}

.b-button--primary:active,
.b-button--primary:hover,
.b-button--primary:focus {
  color: var(--font-light-color);
  background: var(--button-color-active);
}

.b-button--primary:not(:disabled):not(.disabled).active:focus,
.b-button--primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem var(--outline-primary-color);
}

/* secondary button */
.b-button--secondary {
  color: var(--font-light-color);
  background: var(--button-secondary-color);
}

.b-button--secondary:disabled,
.b-button--secondary:disabled:active,
.b-button--secondary:disabled:hover,
.b-button--secondary:disabled:focus {
  filter: grayscale(1) brightness(1.2);
  background: var(--button-color-disabled);
}

.b-button--secondary:active,
.b-button--secondary:hover,
.b-button--secondary:focus {
  color: var(--font-light-color);
  background: var(--button-secondary-color-active);
}

.b-button--secondary:not(:disabled):not(.disabled).active:focus,
.b-button--secondary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem var(--outline-primary-color);
}

/* tertiary button */
.b-button--tertiary {
  color: var(--font-light-color);
  background: var(--button-tertiary-color);
}

.b-button--tertiary:disabled,
.b-button--tertiary:disabled:active,
.b-button--tertiary:disabled:hover,
.b-button--tertiary:disabled:focus {
  filter: grayscale(1) brightness(1.2);
  background: var(--button-color-disabled);
}

.b-button--tertiary:active,
.b-button--tertiary:hover,
.b-button--tertiary:focus {
  color: var(--font-light-color);
  background: var(--button-tertiary-color-active);
}

.b-button--tertiary:not(:disabled):not(.disabled).active:focus,
.b-button--tertiary:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem var(--outline-primary-color);
}

.b-button--dark {
  color: var(--font-light-color);
  background: var(--button-dark-gradient);
}

.b-button--dark:active,
.b-button--dark:hover,
.b-button--dark:focus {
  color: var(--font-light-color);
  background: var(--button-dark-gradient-active);
  opacity: 0.9;
}

.b-button--dark:not(:disabled):not(.disabled).active:focus,
.b-button--dark:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem hsla(
    var(--primary-color-hsl-hue),
    0%,
    var(--hsl-light),
    50%
  );
}

/* fix MaterialUI drawer x scroll */
.MuiDrawer-paper {
  overflow-x: hidden;
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
