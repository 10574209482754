.b-screen-handler__wrapper {
}

.b-screen-handler__item {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.with-main-menu .b-screen-handler__item {
  min-height: calc(100vh - 50px);
}

.with-main-menu.with-navbar .b-screen-handler__item {
  min-height: calc(100vh - 44px - 50px);
}
